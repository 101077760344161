import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import SEO from '../components/SEO';
import Header from '../components/Header';
import ContentRow from '../components/ContentRow';
import ResponsiveImg from '../components/ResponsiveImg';
import { openFindNeosurf, commonHeaderColor } from '../util';


const IndexPage = ({ data }) => (
  <>
    <SEO
      title="Buy Neosurf - Vouchers, Codes &amp; Neosurf Casinos Online"
      description="Buy Neosurf codes &amp; vouchers and pay securely on the web. Check out the list of reputable Neosurf Casinos online."
      alternateLanguagePages={[
        {
          path: '',
          language: 'en'
        },
        {
          path: '',
          language: 'fr'
        }
      ]}
    />
    <Header backgroundColor={commonHeaderColor} />
    <ContentRow backgroundColor={commonHeaderColor} padV>
      <div className="grid c-2">
        <div>
          <a href="https://www.auslots.com/?a_aid=5a6fb263dabe6&amp;a_bid=1405c1db" target="_blank" rel="noopener noreferrer">
            <Img fluid={data.img1.childImageSharp.fluid} alt="Buy Neosurf &amp; pay securely online" />
          </a>
        </div>
        <div>
          <div className="contentBox textAlignCenter">
            <h1>Buy Neosurf &amp; Pay on the Web</h1>
            <p className="textColorDark">If you want to keep on making online payments but don't like having to enter sensitive details, why not give <strong>Neosurf</strong> a try? It's a <strong>safe and secure payment method</strong> that involves the use of vouchers. It's designed to make online payments a lot safer and more convenient.</p>
          </div>
        </div>
      </div>
    </ContentRow>
    <ContentRow padV>
      <div className="grid c-2">
        <div>
          <div className="contentBox">
            <h2 className="smaller textUppercase">Why Neosurf?</h2>
            <p>There are dozens of different payment methods available today. Online casinos typically accept anything from a few to over 20 of them. With so many available choices, why should you pick Neosurf as your payment method?</p>
            <p>One of the main reasons you should use Neosurf is it's very safe and secure. When using it online, you <strong>don't need to provide any of your personal or banking details</strong>. This is because Neosurf is a voucher system. It's accepted at lots of gaming, gambling and general entertainment sites, along with lots of other websites. You can even use it to top up your balance on various prepaid cards and e-wallets.</p>
            <p>The online gambling industry is changing on a daily level. Some of the most popular e-wallets and payment methods are no longer available in certain markets. This is where Neosurf shies. You can purchase Neosurf codes in over <strong>135,000 retail outlets</strong> all over the world.</p>
          </div>
        </div>
        <div>
          <Img fluid={data.img2.childImageSharp.fluid} alt="Neosurf codes available in 135000 stores worldwide" />
        </div>
      </div>
    </ContentRow>
    <div className="pageWidth">
      <h2 className="smaller textAlignCenter textUppercase">Get Neosurf - 3 Easy Steps</h2>
      <p className="textAlignCenter">One of the reasons Neosurf is a good payment method is its convenience.<br />Using it is very simple indeed. In fact, there are just three steps to follow.</p>
      <div className="grid c-3 bigGap alignItemsTop">
        <div>
          <div className="contentBox border accent textAlignCenter">
            <h3 className="smaller">1. Find a reseller</h3>
            <p>First, you need to visit a <strong>point of sale</strong>. There are more than 135,000 of these locations throughout the world. To find the nearest one to you, simply follow this link.</p>
          </div>
        </div>
        <div>
          <div className="contentBox border accent textAlignCenter">
            <h3 className="smaller">2. Buy a PIN code</h3>
            <p>Second, you need to purchase a <strong>Neosurf voucher</strong>. The vouchers can be bought in several different amounts ranging from as low as €10 to as high as €200. Each voucher you purchase has a 10-digit PIN code on it.</p>
          </div>
        </div>
        <div>
          <div className="contentBox border accent textAlignCenter">
            <h3 className="smaller">3. Pay online!</h3>
            <p>Third, when you want to use Neosurf online, simply <strong>enter the 10-digit PIN code</strong>. Any money left over can be spent using the same code. Alternatively, you can go online and transfer it to a new voucher.</p>
          </div>
        </div>
      </div>
      <p className="textAlignCenter">To check a voucher's balance, simply visit the Neosurf website and head to the ‘My Card' section.<br />Simply enter your 10-digit PIN code to see the balance of that particular voucher.</p>
    </div>
    <ContentRow backgroundColor="#eee">
      <div className="grid c-2">
        <div>
          <div className="contentBox">
            <h2 className="smaller textUppercase">Pay &amp; Play Online with Neosurf</h2>
            <p className="textColorDark">Don't want to use debit/credit cards to pay online? Or, you don't even have one? You might not want to provide sensitive banking details as privacy is your main concern?</p>
            <p>Neosurf may be the <strong>perfect solution for you</strong>! Buy a Neosurf voucher for cash and pay &amp; play online safely. Simple as that! Watch a short video explaining how Neosurf voucher system works.</p>
          </div>
        </div>
        <div>
          <div className="videoWrapper">
            <iframe
              title="neosurfVideo"
              src="https://www.youtube.com/embed/ZZKglPJVhFE"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            >
            </iframe>
          </div>
        </div>
      </div>
    </ContentRow>
    <ContentRow
      backgroundColor="rgba(255,0,155, 0.8)"
      Img={
        <ResponsiveImg
          aspectRatio={data.img3.childImageSharp.fixed.aspectRatio}
          src={data.img3.childImageSharp.fixed.src}
          alt=""
        />
      }
      padV
    >
      <div className="contentBox textAlignCenter">
        <h2 className="smaller textColorComplementary">Find Neosurf Retailer Near You</h2>
        <p className="textColorLight">Customers like Neosurf cash vouchers as they are available from thousands of convenient locations around the world. Today you will find Neosurf cash vouchers in <strong className="textColorComplementary">convenience stores, supermarkets, gas stations, newsstands, tobacco shops, kiosks, internet cafés and more</strong>.</p>
        <button className="button big" onClick={openFindNeosurf}>Where to find Neosurf?</button>
      </div>
    </ContentRow>
    <div className="pageWidth">
      <h2 className="smaller textAlignCenter textUppercase">Neosurf Casinos &amp; Partners</h2>
      <p className="textAlignCenter">One of the many things you can use Neosurf for is funding online gambling activity. More specifically, you can use it to deposit money into online casino accounts. A growing number of casinos have started accepting this payment method because of how safe and secure it is. Check out recommended <strong>Neosurf casinos</strong> &amp; partners.</p>
      <div className="grid c-3 bigGap" style={{ alignItems: 'flex-start' }}>
        <div>
          <div className="contentBox">
            <a href="https://www.luckystar.io/?a_aid=5a6fb263dabe6&amp;a_bid=c6328648">
              <Img fluid={data.luckystar.childImageSharp.fluid} alt="" />
            </a>
            <h3>LuckyStar</h3>
            <p className="zeroMarginT">Guided by the motto <strong>"Happy players are the best advertisement"</strong>, LuckyStar Casino represents a true star in the online gambling industry. Over 1000 casino games, wide-range of payment methods including Neosurf and award-winning customer support, guarantee you a superb gaming experience. Join today and claim <strong>100% Welcome Bonus</strong>.</p>
            <a href="https://www.luckystar.io/?a_aid=5a6fb263dabe6&amp;a_bid=c6328648" className="button">Visit Now</a>
          </div>
        </div>
        <div>
          <div className="contentBox">
            <a href="https://www.betbtc.io/sign-up/a48acf12">
              <Img fluid={data.betbtc.childImageSharp.fluid} alt="" />
            </a>
            <h3>BetBTC Casino</h3>
            <p className="zeroMarginT">BetBTC is a 'hybrid' fiat &amp; crypto Casino, meaning your account will be in fiat currency, but you will be able to deposit and withdraw with both fiat transfer methods &amp; BITCOIN, which will be automatically exchanged to it's fiat value! With modern design, fast platform, huge array of games and the best Bonuses – BetBTC Casino truly has it all! Oh and yes, it will accept players from all over!</p>
            <a href="https://www.betbtc.io/sign-up/a48acf12" className="button">Visit Now</a>
          </div>
        </div>
        <div>
          <div className="contentBox">
            <a href="https://www.auslots.com/?a_aid=5a6fb263dabe6&amp;a_bid=1405c1db">
              <Img fluid={data.auslots.childImageSharp.fluid} alt="" />
            </a>
            <h3>AUSlots Casino</h3>
            <p className="zeroMarginT">Looking for some awesome slot games? Unique &amp; modern online slot machines? AUSlots may be the perfect place for you! With over <strong>1000 casino games</strong> including the latest slots and live casino games, AUSlots delivers great entertainment and unparalleled gaming experience. Join today and grab 150 $/€ on the House!</p>
            <a href="https://www.auslots.com/?a_aid=5a6fb263dabe6&amp;a_bid=1405c1db" className="button">Visit Now</a>
          </div>
        </div>
        <div>
          <div className="contentBox">
            <a href="https://www.auslots.com/?a_aid=5a6fb263dabe6&amp;a_bid=1405c1db">
              <Img fluid={data.emu.childImageSharp.fluid} alt="" />
            </a>
            <h3>Emu Casino</h3>
            <p className="zeroMarginT">Emu Casino is one of the top rated Australian online casinos that accept Neosurf deposit method Over a thousand of games from world's best Game Providers, including over 900 pokies!</p>
            <a href="https://www.auslots.com/?a_aid=5a6fb263dabe6&amp;a_bid=1405c1db" className="button">Visit Now</a>
          </div>
        </div>
        <div>
          <div className="contentBox">
            <a href="http://record.777slotsbay-affiliate.com/text/c863cf75000019000002000000000001000001000001">
              <Img fluid={data.slotsBay.childImageSharp.fluid} alt="" />
            </a>
            <h3>777 Slots bay</h3>
            <p className="zeroMarginT">777SlotsBay, a new online casino from a team with years of experience, brings you all the latest and best in slots and casino games, playable on all devices. And it accepts Neosurf!</p>
            <a href="http://record.777slotsbay-affiliate.com/text/c863cf75000019000002000000000001000001000001" className="button">Visit Now</a>
          </div>
        </div>
      </div>
      <h2 className="smaller textAlignCenter textUppercase zeroMarginB">FAQ</h2>
      <div className="grid c-2 bigGap">
        <div>
          <Img className="roundedBorder" fluid={data.img4.childImageSharp.fluid} alt="Withdrawals using Neosurf" />
        </div>
        <div>
          <div className="contentBox">
            <h3 className="smaller textUppercase">Can I withdraw from online casinos using Neosurf?</h3>
            <p>Unfortunately, it's not possible to make withdrawals using this payment method. Casinos will let you use another payment method to withdraw your funds.</p>
          </div>
        </div>
        <div>
          <div className="contentBox">
            <h3 className="smaller textUppercase">Will I get charged for using this payment method?</h3>
            <p>You'll only get charged if you purchase vouchers in one currency and use them to make payments in another currency. In this case, an exchange fee of 2% (3% for transactions involving the Polish zloty) will be debited. There's also an inactivity fee of €2 per month charged to voucher accounts that have been inactive for at least six months or one year after purchase.</p>
          </div>
        </div>
        <div>
          <Img className="roundedBorder" fluid={data.img5.childImageSharp.fluid} alt="Frequently asked questions" />
        </div>
      </div>
    </div>
  </>
)

export default IndexPage;

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    img1: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "home/mac1.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 1080,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img2: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "home/model-card.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 899,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img3: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "bg2.jpg"}) {
      childImageSharp {
        fixed(width: 524) {
          aspectRatio
          src
        }
      }
    }
    img4: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "home/home_4.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 505,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img5: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "home/home_6.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 505,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    luckystar: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "home/ls.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 940,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    betbtc: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "home/betbtc.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 600,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    auslots: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "home/AUSlots-Casino-home.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 940,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    emu: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "home/emucasino-logo.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 940,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    slotsBay: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "home/777-slots-bay-logo-getneosurf.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 940,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
